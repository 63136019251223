var { client } = require('clearthru')
var { Frame } = require('../framework.js')

import { frmFormulaires } from './formulaires.js'
import { frmUsers } from './users.js'

export async function admin(api, on_logout) {

  $('body').addClass('has-navbar-fixed-top')

  const node = $('body')
  const template = 'main'
  const frame = new Frame({ node, template })

  var burger = frame.$('.burger')
  var nav = frame.$('#navMenu')
  burger.on('click', function(){
    burger.toggleClass('is-active')
    nav.toggleClass('is-active')
  })
  nav.on('click', 'li > a', function(){
    burger.removeClass('is-active')
    nav.removeClass('is-active')
  })

  frame.$('.navbar-menu a.logout').on('click', function () {
    localStorage.removeItem('credentials')
    client.unlink(api)
    frame.remove()
    on_logout()
  })

  const ctx = {
    node: frame.$('div.frames'),
    nav: frame.$('aside.menu ul.menu-list.general,#navMenu ul.menu-list.general'),
    menu: frame.$('aside.menu,#navMenu'),
  }
  const bkctx = {
    node: frame.$('div.frames'),
    nav: frame.$('aside.menu ul.menu-list.bookkeeping,#navMenu ul.menu-list.bookkeeping'),
    menu: frame.$('aside.menu,#navMenu'),
  }

  var isSuperviseur = await api.isSuperviseur()

  var navs = []
  navs.push(frmFormulaires(api, ctx))
  if(isSuperviseur) {
    navs.push(frmUsers(api, ctx))
  }

  navs = await Promise.all(navs)
  navs[0].show()

  frame.$('nav a.username').html(await api.getUsername())

  frame.show()

}
