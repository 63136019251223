
var { Frame } = require('./framework.js')

export const fieldErrMsg = {
  'required': 'Ce champ est requis.',
  'unique': 'Cette valeur existe déjà.',
  'Date': 'Ce champ doit être une date. (YYYY-MM-DD)',
  'Number':'Ce champ doit être un nombre.'
}

export function ctxify(obj) {
  const ctx = (obj instanceof jQuery) ? obj : $(obj)
  return function (sel) {
    return (sel) ? ctx.find(sel) : ctx
  }
}

export function randomId() {
  return ("00000000000" + Math.random().toString(36).substring(2)).substr(-11,11)
}

export function debounce(func, wait, immediate) {
  var timeout
  return function() {
    var context = this
    var args = arguments
    var later = function() {
      timeout = null
      if (!immediate) {
        func.apply(context, args)
      }
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) {
      func.apply(context, args)
    }
  }
}

export function exclusive(func) {
  var pending
  return function (...args) {
    if(pending) {
      return
    }
    pending = Promise.resolve()
    .then(() => {
      return func(...args)
    })
    .finally(() => {
      pending = null
    })
  }
}

export function thisError(name, cb) {
	if(typeof name === 'string') {
		name = [name]
	}
	return function (err) {
		if(name.includes(err.name)) {
			return cb(err)
		} else {
			throw err
		}		
	}
}

export function thisRemoteError(name, cb) {
	if(typeof name === 'string') {
		name = [name]
	}
	return function (err) {
		if(err.name == 'RemoteError' && name.includes(err.remote.name)) {
			return cb(err.remote)
		} else {
			throw err
		}		
	}
}

export function showModal({
  title = 'Confirmation',
  message = '',
  btnOk = 'OK',
  btnOkStyle = 'is-danger',
  btnCancel = 'Annuler',
  onOk = () => {},
  onCancel = () => {}
}) {
  var modal = $(`<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-head">
        <p class="modal-card-title">${title}</p>
      </section>
      <section class="modal-card-body">
        ${message}
      </section>
      <footer class="modal-card-foot">
        <button class="ok button ${btnOkStyle}">${btnOk}</button>
        <button class="cancel button">${btnCancel}</button>
      </footer>
    </div>
    <button class="modal-close is-large" aria-label="close"></button>
  </div>
  `)
  return () => {
    $('body').append(modal)
    modal.find(".modal-background,button").on('click', () => {
      modal.remove()
    })
    modal.find("button.ok").on('click', () => {
      onOk(modal)
    })
    modal.find("button.cancel").on('click', onCancel)
  }
}


export class Modal extends Frame {
  constructor(template) {
    super({ node: $('body'), template })
    const self = this
    this.$(".modal-background,button.cancel,button.close,button.modal-close").on('click', () => {
      this.close()
    })
  }
  show() {
    super.show()
    this.$('input.focus').focus()
    return this
  }
  close() {
    this.$().remove()
  }
}

export class NavFrame extends Frame {
  constructor(options) {
    super(options)
    const self = this
    this.nav = options.nav
    this.menu = options.menu
    var label = options.label || ''
    var docid = options.docid || ''
    this.closable = options.closable

    const htmlNav = `<li class="nav_${this.id}" label="${label}" docid="${docid}"><a frame="frame_${this.id}"><span class="menulabel">${label}</span></a><ul></ul></li>`
    const htmlNavClosable = `
      <li class="nav_${this.id}" label="${label}" docid="${docid}">
        <a frame="frame_${this.id}" class="control has-icons-right"><span class="menulabel">${label}</span><span class="icon is-small is-right">
            <i class="fas fa-times-circle fa-xs"></i>
          </span>
        </a>
        <ul></ul>
      </li>`
    for(let n of this.nav.get()) {
      let menuItem = $(this.closable ? htmlNavClosable : htmlNav)
      $(n).append(menuItem)
      menuItem.on('click', '> a > span > svg', function (e) {
        e.stopPropagation()
        self.close()
      }) 
      menuItem.on('click', '> a', function (e) {
        self.show()
      }) 
    }
    this.menuItem = $(`.nav_${this.id}`)
    this.subNav = this.menuItem.find('ul')

  }
  label(text) {
    if(!text) {
      return this.menuItem.attr('label')
    } else {
      this.menuItem.attr('label', text)
      this.menuItem.find('a > span.menulabel').each(function () {
        $(this).first().text(text)
      })
    }
  }
  close() {
    this.menuItem.closest('ul').parent().children('a').click()
    this.$().remove()
    this.menuItem.remove()
  }
  show() {
    this.node.off('scroll').on('scroll', debounce(() => {
      this.scrollTop = this.node.scrollTop()
    }, 250))
    this.menu.find('a').not(this.menuItem.children('a')).removeClass('is-active')
    this.menuItem.children('a').addClass('is-active')
    super.show()
    for(var sibling of this.$().siblings().get()) {
      $(sibling).data("Frame").hide()
    }
    this.node.scrollTop(this.scrollTop || 0)
    return this
  }
}
