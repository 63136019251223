const merge = require('lodash/merge')

function ctxify(obj) {
  const ctx = (obj instanceof jQuery) ? obj : $(obj)
  return function (sel) {
    return (sel) ? ctx.find(sel) : ctx
  }
}

function randomId() {
  return ("00000000000" + Math.random().toString(36).substring(2)).substr(-11,11)
}

function debounce(func, wait, immediate) {
  var timeout
  return function() {
    var context = this
    var args = arguments
    var later = function() {
      timeout = null
      if (!immediate) {
        func.apply(context, args)
      }
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) {
      func.apply(context, args)
    }
  }
}

var Events = exports.Events = class {
  constructor() {
    this._events = {}
  }
  on(ev, fn) {
    if(!this._events[ev]) {
      this._events[ev] = []
    }
    if(!this._events[ev].includes(fn)) {
      this._events[ev].push(fn)
    }
  }
  off(ev, fn) {
    if(!this._events[ev]) {
      return
    }
    if(fn) {
      var index = this._events[ev].indexOf(fn)
      if (index !== -1) {
        this._events[ev].splice(index, 1)
      }
    } else {
      this._events[ev] = []
    }
  }
  emit(ev, ...args) {
    if(!this._events[ev]) {
      return
    }
    for(let fn of this._events[ev]) {
      fn(...args)
    }
  }
}

var Frame = exports.Frame = class extends Events {
  constructor(options) {
    super()
    this.options = options
    this.node = options.node
    this.template = options.template
    this.id = randomId()
    this.frame = $(`templates .template#${this.template}`).clone()
    this.frame.removeAttr("id").removeClass('template').addClass('frame').addClass(`frame_${this.id}`)
    this.tag = this.frame.prop("tagName")
    this.frame.data('Frame', this)

    if(this.tag == "TR") {
      this.display = {
        frame: this.frame.children('td').animate({ "padding-left": 0, "padding-right": 0, }).wrapInner('<div />').children(),
        show: 'slideDown',
        hide: 'slideUp',
      }
    } else {
      this.display = {
        frame: this.frame,
        show: 'show',
        hide: 'hide',
      }
    }
    this.display.frame.hide()

    this.frame.find('.template').remove()
    if(this.node) {
      this.node.append(this.frame)
    }
    this.$ = ctxify(this.frame)
    this.$().data("Frame", this)

    this.$('div[clone][clone!=""]').get()
    .map((clone) => {
      $(clone).append($(`templates #${$(clone).attr('clone')}`).clone())
    })

    this.forms = element_attach(this.frame, 'form.framework', Form)
    this.form = this.forms._first

    this.tables = element_attach(this.frame, 'table.framework', Table)
    this.table = this.tables._first

  }
  show(duration = 0, easing = 'swing') {
    return new Promise((resolve) => {
      this.display.frame[this.display.show](duration, resolve)    
    })
    .then(() => {
      this.emit('show')
    })
  }
  hide(duration = 0, easing = 'swing') {
    return new Promise((resolve) => {
      this.display.frame[this.display.hide](duration, resolve)    
    })
    .then(() => {
      this.emit('hide')
    })
  }
  remove() {
    this.frame.remove()
    return this
  }
}

class Table {
  constructor(node) {
    this.node = node
    this.clickable = node.hasClass('clickable')
    this.$ = ctxify(node)
    const self = this
    this.node.on('click', 'tbody tr.entry', function(event) {
      if(self.onClick_fn) {
        var entry = $(this).data('entry')
        self.onClick_fn(entry)
      }
    })
    this.node.on('click', 'tbody a[actionbtn]', function(event) {
      if(self.onClick_fn) {
        var entry = $(this).parentsUntil("tbody", "tr").data('entry')
        var action = $(this).attr("actionbtn")
        self.onClick_fn(entry, action)
      }
      return false
    })
  }
  onClick(fn) {
    this.onClick_fn = fn
  }
  insertFrame(template, docid) {
    const row = new Frame({ template })
    row.frame.attr('docid', docid)
    var parentRow = this.$('> tbody').find(`tr.entry[docid="${docid}"]`).first()
    if(parentRow.length) {
      parentRow.after(row.frame)
    } else {
      this.$('> tbody').append(row.frame)
    }
    return row
  }
  message(message, docid, className = 'is-primary') {
    //this.frame.children('td').animate({ "padding-left": 0, "padding-right": 0, }).wrapInner('<div />').children()
    const row = $(`
    <tr><td colspan="100"><article class="message ${className}">
      <div class="message-body">
        ${message}
      </div>
    </article></td></tr>`)
    var article = row.children('td').animate({ "padding-left": 0, "padding-right": 0, }).wrapInner('<div />').children().hide()
    var parentRow = this.$('> tbody').find(`tr.entry[docid="${docid}"]`).first()
    if(parentRow.length) {
      parentRow.after(row)
    } else {
      this.$('> tbody').append(row)
    }
    article.slideDown(400).delay(5000).slideUp(400, () => {
      row.remove()
    })
    return this
  }
	update(model) {
		var clickableClass = (this.clickable) ? 'clickable' : ''
		var headfield = []
		this.$('> thead > tr > th[field]').each(function () {
      var field = $(this).attr('field')
      var classes = $(this).attr('class') || ''
      var html = $(this).find('template').html()
      var cast = $(this).attr('cast')
			headfield.push({ field, classes, html, cast })
		});
		if(model.body instanceof Array) {
			this.$('> tbody').empty();
			model.body.forEach((doc) => {
				this.$('> tbody').append(createrow(doc));
				var row = this.$('> tbody').find('tr.entry[docid="' + doc['_id'] + '"]');
				row.data('entry', doc);
			});
		} else if(model.remove) {
			this.$('> tbody').find('tr.entry[docid="' + model.remove + '"]').remove();
		} else if(model.entry){
			var doc = model.entry;
			var row = this.$('> tbody').find('tr.entry[docid="' + doc['_id'] + '"]');
			if(row.length) {
				row.replaceWith(createrow(doc));
			} else {
				this.$('> tbody').prepend(createrow(doc));
			}
			row = this.$('> tbody').find('tr.entry[docid="' + doc['_id'] + '"]');
			row.data('entry', doc);
		}
		if(model.footer) {
			for(var i in model.footer) {
				this.$('> tfoot .' + i).html(model.footer[i]);
			}
		}
		function createrow(doc) {
			var html_class = doc._html_class || ''
			var tr = $('<tr docid="' + doc['_id'] + '" class="entry ' + clickableClass + ' ' + html_class + '"></tr>')
			for(var i in headfield) {
				if(headfield[i].field == '_actionbtn') {
					$('<td class="' + headfield[i].classes + '">' + headfield[i].html + '</td>').appendTo(tr)
				} else {
					var props = headfield[i].field.split('.')
					var data = nestGet(doc, props, headfield[i].cast)
          if(headfield[i].classes.includes('img')) {
            if(data) {
              var url = window.URL.createObjectURL(new Blob([data]))
              var img = $('<img/>')
              var imgtag = img[0]
              imgtag.onload = function () {
                window.URL.revokeObjectURL(imgtag.src)
              }
              imgtag.src = url
              img.wrap('<td field="' + headfield[i].field + '" class="' + headfield[i].classes + '"></td>').parent().appendTo(tr)
            } else {
              $('<td field="' + headfield[i].field + '" class="' + headfield[i].classes + '">&nbsp;</td>').appendTo(tr)
            }
          } else {
            var td = data
            if(td === undefined) {
              td = '<em>(undefined)</em>'
            }
            $('<td field="' + headfield[i].field + '" class="' + headfield[i].classes + '">' + td + '</td>').appendTo(tr)
          }
				}
			}
			return tr
		}	
    return this
	}	
  show(duration = 0, easing = 'swing') {
    return new Promise((resolve) => {
      this.frame.show(duration, easing, resolve)    
    })
  }
  hide(duration = 0, easing = 'swing') {
    return new Promise((resolve) => {
      this.frame.hide(duration, easing, resolve)    
    })
  }
}


function element_attach(frame, selector, cls) {
  return $(selector, frame)
  .filter(function () {
    return $(this).parentsUntil(frame, '.arrayfield').length < 1;
  })
  .get()
  .map((node) => {
    node = $(node)
    return [node.attr('name'), new cls(node)]
  })
  .reduce((obj, e, idx) => {
    if(!obj._first) {
      Object.defineProperty(obj, '_first', {
        value: e[1],
      })
    }
    obj[e[0] || idx] = e[1]
    return obj
  }, {})
}

function dateTime(x) {
  var r = new Date(x)
  return r.toISOString().substr(0,10) + ' ' + r.toLocaleTimeString()
}

var getCastor = {
  'Date': (x) => x && new Date(x).toISOString().substr(0,10),
  'DateTime': (x) => x && dateTime(x),
  'Currency': (x) => !isNaN(parseFloat(x)) && (parseFloat(x).toFixed(2) + ' $'),
  'OptionalCurrency': (x) => (!isNaN(parseFloat(x)) && x != 0) && (parseFloat(x).toFixed(2) + ' $') || '',
}
var setCastor = {
  'Date': (x) => x.substr(0,10),
  'Boolean': (x) => ((x == "true") ? true : false),
}

function nestGet(obj, keys, cast) {
  var key = keys.shift()
  if(keys.length) {
    var nestedObj = obj[key] || {}
    return nestGet(nestedObj, keys, cast)
  } else {
    return (getCastor[cast] || ((x) => x))(obj[key])
  }
}

function nestSet(obj, keys, val, cast) {
  var key = keys.shift()
  if(keys.length) {
    var nestedObj = obj[key] || (obj[key] = {})
    nestSet(nestedObj, keys, val, cast)
  } else {
    obj[key] = (setCastor[cast] || ((x) => x))(val)
  }
}


function pack_file(file) {
  return new Promise((resolve) => {
    var reader = new FileReader()
    reader.onload = () => {
      resolve(Buffer.from(reader.result))
    }
    reader.readAsArrayBuffer(file)  
  })
}

async function input_pack(data, input) {
  var keys = (input.attr('field') || input.attr('name')).split('.')
  var type = input.attr('type')
  if(type == 'file') {
    if(input[0].files.length) {
      var f = input[0].files[0]
      if (!f.type.match('image.*')) {
        return
      }
      var val = await pack_file(f)
      if(val) {
        nestSet(data, keys, val)
      }
    }
  } else {
    var val = (type == 'checkbox') ? input.prop('checked') : input.val()
    var cast = (type != 'checkbox') ? input.attr('cast') : undefined
    nestSet(data, keys, val, cast)
  }
}

async function form_pack(node) {

  function form_select(node) {
    return $('input[type="radio"][name][name!=""]:checked,input[type!="radio"][field][field!=""],select[field][field!=""],textarea[field][field!=""]', node)
    .filter(function () {
      return $(this).parentsUntil(node, '.arrayfield').length < 1;
    })
  }

  var data = {}
  var inputs = form_select(node).get()
  for(let input of inputs) {
    await input_pack(data, $(input))
  }
  return data
}

function form_showif(e) {
  if(e.val() == 'true') {
    e.parents('div.field').first().find('div.showiftrue').show()
    e.parents('div.field').first().find('div.showiffalse').hide()
  } else {
    e.parents('div.field').first().find('div.showiftrue').hide()
    e.parents('div.field').first().find('div.showiffalse').show()
  }
}

function form_load(node, data) {

  function arrayfields() {
    return $(this).parentsUntil(node, '.arrayfield').length < 1;
  }

  $('input[field][field!=""][type!="radio"][type!="checkbox"][type!="file"],select[field][field!=""],textarea[field][field!=""]', node)
  .filter(arrayfields)
  .each(function () {
    var input = $(this)
    var keys = input.attr('field').split('.')
    var cast = input.attr('cast')
    var val = nestGet(data, keys, cast)
    val = isNaN(val) ? (val || '') : val
    input.val(val)
  })
  $('input[field][field!=""][type="checkbox"]', node)
  .filter(arrayfields)
  .each(function () {
    var input = $(this)
    var keys = input.attr('field').split('.')
    input.prop('checked', nestGet(data, keys))
  })
  $('input[type="radio"].iterate', node)
  .filter(arrayfields)
  .each(function () {
    var keyName = $(this).attr('name')
    var keys = keyName.split('.')
    var val = nestGet(data, keys)
    var input = $(`input[type="radio"][name="${keyName}"][value="${val}"]`, node)
    input.prop('checked', true)
    form_showif(input)
  })

  $('td[field][field!=""],span[field][field!=""],p[field][field!=""],div[field][field!=""]', node)
  .filter(arrayfields)
  .each(function () {
    var e = $(this)
    var keys = e.attr('field').split('.')
    var cast = e.attr('cast')
    var val = (nestGet(data, keys, cast) || '') + ''
    e.html(val.replace(/\n/g, '<br />'))
  })

  $('img[field][field!=""]', node)
  .filter(arrayfields)
  .each(function () {
    var img = this
    var keys = $(img).attr('field').split('.')
    var buf = nestGet(data, keys)
    if(buf) {
      img.onload = function () {
        window.URL.revokeObjectURL(img.src)
      }
      img.src = window.URL.createObjectURL(new Blob([buf]))
    }
  })

  if(node._framework && node._framework.tables) {
    for(var key in node._framework.tables) {
      var table = node._framework.tables[key]
      var field = $(table.node).attr('field')
      if(field) {
        var keys = field.split('.')
        table.update({ body: nestGet(data, keys) })
      }
    }
  }
}

class ArrayField {
  constructor(node) {
    this.$ = ctxify(node)
    this.field = this.$().attr('field')
    this.templateRow = this.$('> tbody > tr').first()
    this.isReadonly = this.$().hasClass('readonly')
    this.$('> tbody').empty()
    if(!this.isReadonly) {
      this.$('> tbody').append(this.templateRow.clone())
      this.$('> tbody').on('change', 'input,select,textarea', (e) => {
        var emptyRows = []
        this.$('> tbody > tr').each(function () {
          var row = $(this)
          var empty = true
          $('input[type="text"],select', row).each(function () {
            empty &= ($(this).val() == "")
          })
          if(empty) {
            emptyRows.push(row)
          }
        })
        if(!emptyRows.length) {
          this.$('> tbody').append(this.templateRow.clone())
        } else {
          emptyRows.pop()
          emptyRows.forEach((row) => {
            row.remove()
          })
        }
        if(this.onChange_fn) {
          this.pack()
          .then(this.onChange_fn)
        }
      })
    }
  }
  onChange(fn) {
    this.onChange_fn = fn
  }
  async pack() {
    var rows = []
    this.$('> tbody > tr:not(:last)').each(function () {
      rows.push(form_pack(this))
    })
    rows = await Promise.all(rows)
    var obj = {}
    var keys = this.field.split('.')
    nestSet(obj, keys, rows)
    return obj
  }
  load(data) {
    var appendRows = (num) => {
      while(num--) {
        var tr = this.templateRow.clone()
        tr._framework = {
          tables: element_attach(tr, 'table.framework', Table)
        }
        this.$('> tbody').append(tr)
      }
    }
    var keys = this.field.split('.')
    var dataRows = nestGet(data, keys) || []
    var expectedLen = (this.isReadonly) ? dataRows.length : dataRows.length + 1
    var trRows = this.$('> tbody > tr').get()
    trRows.splice(expectedLen).forEach((tr) => {
      $(tr).remove()
    })
    if(trRows.length < expectedLen) {
      appendRows(expectedLen - trRows.length)
    }
    var trRows = this.$('> tbody > tr').get()
    trRows.forEach((tr, idx) => {
      var data = dataRows[idx]
      if(data) {
        form_load(tr, data)
      }
    })
  }
  readonly(readonly) {
    this.$('input[type!="radio"][type!="checkbox"],select,textarea')
    .each(function () {
      if(readonly) {
        $(this).attr('readonly', 'readonly')
      } else {
        $(this).removeAttr("readonly")
      }
    })
    this.$('input[type="radio"],input[type="checkbox"]')
    .each(function () {
      if(readonly) {
        $(this).attr('disabled', 'disabled')
      } else {
        $(this).removeAttr("disabled")
      }
    })
  }
  clear() {
    this.$('> tbody').empty()
    if(!this.isReadonly) {
      this.$('> tbody').append(this.templateRow.clone())
    }
  }
  error(message, target) {
    if(target.indexOf(this.field) != 0) {
      return
    }
    var feedback = $(`<p class="feedback help is-danger">${message}</p>`)

    var keys = target.replace(`${this.field}.`, '').split('.')
    var idx = +keys.shift()
    var inputField = keys.join('.')
    var input = this.$('> tbody > tr').eq(idx).find(`[field="${inputField}"]`)

    input.after(feedback)
    input.addClass('is-danger')
    input.on('focus', () => {
      feedback.remove()
      input.removeClass('is-danger')
      input.off('focus')
    })
  }
}

class Form {
  constructor(node) {
    this.node = node
    this.$ = ctxify(node)

    function form_select(node) {
      return $('input[type="radio"][name][name!=""],input[type!="radio"][field][field!=""],select[field][field!=""],textarea[field][field!=""]', node)
      .filter(function () {
        return $(this).parentsUntil(node, '.arrayfield').length < 1;
      })
    }

    form_select(node)
    .on('change', async (e) => {
      if(e.target.type == 'radio') {
        form_showif($(e.target))
      }
      if(this.onChange_fn) {
        var data = {}
        await input_pack(data, $(e.target))
        this.onChange_fn(data)
      }
    })

    this.arrayfields = this.$('.arrayfield:not(.readonly)').get()
    .map((af) => {
      return new ArrayField($(af))
    })
    this.arrayfields.forEach((af) => {
      af.onChange((data) => {
        if(this.onChange_fn) {
          this.onChange_fn(data)
        }
      })
    })

    this.arrayfieldviews = this.$('.arrayfieldview, .arrayfield.readonly').get()
    .map((af) => {
      return new ArrayField($(af))
    })

    this.$('div.showiftrue').hide()
  }
  onChange(fn) {
    this.onChange_fn = fn
  }
  async pack() {
    var data = await form_pack(this.node)
    var afs = await Promise.all(this.arrayfields.map((af) => {
      return af.pack()
    }))
    return merge(data, ...afs)
  }
  load(data) {
    form_load(this.node, data)
    this.arrayfields.map((af) => {
      return af.load(data)
    })
    this.arrayfieldviews.map((af) => {
      return af.load(data)
    })
  }
  readonly(readonly) {
    this.$('input[type!="radio"][type!="checkbox"],select,textarea')
    .filter(function () {
      return $(this).parents('.arrayfield').length < 1;
    })
    .each(function () {
      if(readonly) {
        $(this).attr('readonly', 'readonly')
      } else {
        $(this).removeAttr("readonly")
      }
    })
    this.$('input[type="radio"],input[type="checkbox"]')
    .each(function () {
      if(readonly) {
        //$(this).attr('disabled', 'disabled')
        $(this).on('click', () => false).on('keydown', () => false)
      } else {
        //$(this).removeAttr("disabled")
        $(this).off('click').off('keydown')
      }
    })
    this.arrayfields.forEach((af) => {
      af.readonly(readonly)
    })
  }
  clear() {
    this.$('input[type!="radio"][type!="checkbox"],select,textarea')
    .filter(function () {
      return $(this).parents('.arrayfield').length < 1;
    })
    .each(function () {
      $(this).val("")
    })
    this.$('input[type="radio"][type="checkbox"]:checked')
    .each(function () {
      $(this).prop('checked', false)
    })
    this.arrayfields.forEach((af) => {
      af.clear()
    })
    this.$('div.showiftrue').hide()
  }
  error(message, target) {
    if(target) {
      var field = this.$(`input.iterate[type="radio"][name="${target}"]:not(.is-danger),input[type!="radio"][field="${target}"]:not(.is-danger),select[field="${target}"]:not(.is-danger),textarea[field="${target}"]:not(.is-danger)`).parents('div.field,td.field').first()
      if(!field.length) {
        this.arrayfields.forEach((af) => {
          af.error(message, target)
        })
      } else {
        var feedback = $(`<p class="feedback help is-danger">${message}</p>`)
        field.append(feedback)
        field.find('input,select,textarea').addClass('is-danger')
        field.find('input,select,textarea').on('focus', () => {
          feedback.remove()
          field.find('input,select,textarea').removeClass('is-danger')
          field.find('input,select,textarea').off('focus')
        })
      }
    } else {
      const article = $(`
        <article class="message is-danger" style="display:none;">
          <div class="message-body">
            ${message}
          </div>
        </article>`)
      this.$().append(article)
      article.show(400)
      this.$(`input,textarea`).on('focus', () => {
        this.$(`input,textarea`).off('focus')
        article.hide(400, () => {
          article.remove()
        })
      })    
    }
  }
  message(message, className = 'is-primary') {
    const article = $(`
      <article class="message ${className}" style="display:none;">
        <div class="message-body">
          ${message}
        </div>
      </article>`)
    this.node.append(article)
    article.show(400).delay(5000).hide(400, () => {
      article.remove()
    })
    return this
  }
  show(duration = 0, easing = 'swing') {
    return new Promise((resolve) => {
      this.node.show(duration, easing, resolve)    
    })
  }
  hide(duration = 0, easing = 'swing') {
    return new Promise((resolve) => {
      this.node.hide(duration, easing, resolve)    
    })
  }
}
