

var { Frame } = require('../framework.js')

import { fieldErrMsg, debounce, exclusive, thisRemoteError, NavFrame, showModal, Modal } from '../common.js'

import { frmFormulaire } from './formulaire.js'

export async function frmFormulaires(api, ctx) {

  var formulaires = await api.getFormulairesApi()

  var frameOpts = {
    ...ctx,
    template: 'formulaires',
    label: 'Formulaires',
  }  
  var frame = new NavFrame(frameOpts)


  async function formulaire_load(docid) {
    const formulaireCtx = {
      node: ctx.node,
      menu: ctx.menu,
      nav: frame.subNav,
      docid,
    }
    frmFormulaire(api, await formulaires.formulaire(docid), formulaireCtx)
  }

  frame.tables.formulaires.onClick(async (entry) => {
    var opened = $('li', frame.subNav).filter((idx, e) => {
      return $(e).attr('docid') == entry._id
    })
    if(opened.length) {
      opened.find('a').click()
    } else {
      await formulaire_load(entry._id)
    }
  })

  frame.$('a.newFormulaire').on('click', () => {
    var modal = new Modal('newFormulaire')
    modal.$('button.ok').on('click', async () => {
      var data = await modal.form.pack()
      await formulaires.newFormulaire(data)
      .then(() => {
        new Modal('sendSuccess').show()
        formulaires_list()
        modal.close()
      })
      .catch(thisRemoteError('ValidateError', (err) => {
        modal.form.error(err.detail)
      }))
      .catch((err) => {
        console.log(err)
        modal.form.error("Le formulaire n'a pas pu être envoyé.")
      })
  })

    modal.show()
    modal.$('input[field="courriel"]').focus()    
  })

  async function formulaires_list() {
    var term = frame.$('input.term').val() || ''
    var type = frame.$('select.type').val() || ''
    var statut = frame.$('select.statut').val() || ''
    var sortcol = frame.tables.formulaires.$('th[sort][sort!=""]')
    var sortfield = sortcol.attr('field')
    var sortorder = (sortcol.attr('sort') == "down") ? 1 : -1
    var sort = { [sortfield]: sortorder }
    frame.tables.formulaires.update(await formulaires.list(term, type, statut, sort))
  }

  frame.$('input.term').on('keyup', debounce(formulaires_list, 200))
  frame.$('select.type').on('change', formulaires_list)
  frame.$('select.statut').on('change', formulaires_list)

  frame.tables.formulaires.$('th.sort a').on('click', function () {
    var col = $(this).parent()
    var field = col.attr('field')
    var sort = col.attr('sort')
    col.siblings('.sort').attr('sort', '').find('svg').hide()
    sort = (!sort || sort == 'up') ? 'down' : 'up'
    col.attr('sort', sort)
    col.find('svg').hide()
    col.find(`svg.sort-${sort}`).show()
    formulaires_list()
    return false
  })

  frame.on('show', formulaires_list)

  return frame
}
