
import { fieldErrMsg, thisRemoteError, debounce, NavFrame, Modal, showModal } from '../common.js'

export async function frmFormulaire(api, formulaire, ctx) {

  var frameOpts = {
    ...ctx,
    template: 'formulaire',
    closable:true,
  }
  var frame = new NavFrame(frameOpts)
  frame.$().addClass(`role-admin`)

  var form = frame.form

  function form_load(entry) {
    form.load(entry)
    var label = (entry.data && entry.data.nom) ? `${entry.data.prenom} ${entry.data.nom}` : entry.courriel
    frame.label(label)
    frame.$().removeClass("form-physio form-ergo").addClass(`form-${entry.type}`)
    //form.$('.actions a.state').hide()
  }

  form.$('a.print').on('click', async function () {
    var url = await formulaire.print()
    window.open(url, '_blank')
  })
  
  form.$('a.getUrl').on('click', async function () {
    var url = await formulaire.getUrl()
    window.open(url, '_blank')
  })

  form.$('a.resend').on('click', showModal({
    message: 'Êtes-vous sûr de vouloir réenvoyer ce formulaire?',
    btnOk: 'Réenvoyer',
    onOk: async () => {
      var entry = await formulaire.resend()
      form_load(entry)
    },
  }))

  form.$('a.archive').on('click', showModal({
    message: 'Êtes-vous sûr de vouloir archiver ce formulaire?',
    btnOk: 'Archiver',
    onOk: async () => {
      var entry = await formulaire.archive()
      form_load(entry)
    },
  }))


  var entry = await formulaire.load()
  form_load(entry)

  var applyLanguage = function(node, idxLangue) {
    var nodes = node.find(":not(iframe)").addBack().contents().filter(function() {
      return this.nodeType == 3 && this.data.includes('|')
    })
    for(var n of nodes) {
      var text = n.data.split('|')[idxLangue]
      text = text.replace(/\*\*(.+)\*\*/g, '<b>$1</b>')
      //n.data = text
      $(n).parent().html(text)
    }
  }
  applyLanguage(frame.$(), entry.idxLangue)
  form.$('button.envoyer').hide()
  form.readonly(true)  
  frame.show()

  return frame
}
