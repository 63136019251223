
var { Frame } = require('../framework.js')

import { fieldErrMsg, debounce, exclusive, thisRemoteError, showModal, NavFrame, Modal } from '../common.js'

export async function frmUsers(api, ctx) {

  var usersApi = await api.getUsersApi()

  const frameOpts = {
    ...ctx,
    template: 'users',
    label: 'Utilisateurs',
  }  
  const frame = new NavFrame(frameOpts)
  const form = frame.forms.newUser

  frame.tables.users.onClick(async (entry) => {
    var editing = frame.tables.users.$(`tr.frame[docid="${entry._id}"]`)
    if(editing.length > 0) {
      editing.find('a.cancel').click()
      return
    }
    var row = frame.tables.users.insertFrame('editUsers', entry._id)
    row.form.load(entry)
    row.show(400)
    row.form.$('a.cancel').on('click', async function () {
      await row.hide(400)
      row.remove()
    })
    row.form.$('a.edit').on('click', exclusive(async function () {
      var data = await row.form.pack()
      await usersApi.update(data)
      .then(async (entry) => {
        frame.tables.users.update({ entry })
        await row.hide(400)
        row.remove()
        frame.tables.users.message(`L'utilisateur ${entry.email} a été mis à jour.`, entry._id)
      })
      .catch(thisRemoteError('FormError', (err) => {
        err.form.forEach((field) => {
          row.form.error(fieldErrMsg[field.kind] || field.kind, field.path)
        })
      }))
    }))
    row.form.$('a.passwordReset').on('click', async function () {
      await usersApi.passwordReset(entry._id)
      .then(async (entry) => {
        frame.tables.users.update({ entry })
        frame.tables.users.message(`Le mot de passe pour l'utilisateur ${entry.email} a été réinitialisé. Un courriel a été envoyé.`, entry._id)
      })
    })
    row.form.$('a.effacer').on('click', showModal({
      message: 'Êtes-vous sûr de vouloir effacer cet utilisateur?',
      btnOk: 'Effacer',
      onOk: async () => {
        await usersApi.remove(entry._id)
        await row.hide(400)
        row.remove()
        frame.tables.users.update({ remove: entry._id })
      },
    }))

  })

  async function users_list() {
    var term = '' //frame.$('input.term').val() || ''
    var sortcol = frame.tables.users.$('th[sort!=""]')
    var sortfield = sortcol.attr('field')
    var sortorder = (sortcol.attr('sort') == "down") ? 1 : -1
    var sort = {}
    sort[sortfield] = sortorder
    frame.tables.users.update(await usersApi.list(term, sort))
  }

  form.$('a.cancel').on('click', exclusive(async function () {
    await form.hide(400)
    frame.$('a.newUser').show(400)
  }))
  form.$('a.create').on('click', exclusive(async function () {
    const data = await form.pack()

    await usersApi.update(data)
    .then(async () => {
      form.clear()
      form.$('.focus').focus()
      form.message(`L'utilisateur ${data.email} a été créé.`)
      await users_list()
    })
    .catch(thisRemoteError('FormError', (err) => {
      err.form.forEach((field) => {
        form.error(fieldErrMsg[field.kind] || field.kind, field.path)
      })
    }))
  }))

  frame.$('a.newUser').on('click', function onNewUser() {
    frame.$('a.newUser').hide(400)
    form.show(400)
    form.$('.focus').focus()
  })

  //frame.$('input.term').on('keyup', debounce(users_list, 200))

  frame.tables.users.$('th.sort a').on('click', function () {
    var col = $(this).parent()
    var field = col.attr('field')
    var sort = col.attr('sort')
    col.siblings('.sort').attr('sort', '').find('svg').hide()
    sort = (!sort || sort == 'up') ? 'down' : 'up'
    col.attr('sort', sort)
    col.find('svg').hide()
    col.find(`svg.sort-${sort}`).show()
    users_list()
    return false
  })

  await users_list()

  return frame
}
