
const clearthru = require('clearthru').client

import { login } from './login.js'
import { admin } from './admin/admin.js'
//import { client } from './client/client.js'
import * as actions from './actions.js'

(async function () {

  //****************************************************************************
  // General initialisation

  //browserSupported() 
  
  $('body').on('submit', 'form', function(e) {
    e.preventDefault()
  })

  //****************************************************************************
  // websocket RPC

  clearthru.on('restoreFailed', function (err) {
    console.log('restoreFailed: ' + err)
    location.reload(true)
  })
  clearthru.on('connect', function (err) {
    console.log('connect')
  })
  clearthru.on('disconnect', function (err) {
    console.log('disconnect')
  })

  const url = (location.protocol == "https:" ? "wss:" : "ws:") + "//" + location.host
  const boot = await clearthru.init(url)

  //****************************************************************************
  // Login and dispatch logic

  function on_login(api) {
    if(clearthru.getName(api) == 'role/admin') {
      admin(api, on_logout)
    // } else if(clearthru.getName(api) == 'role/client') {
    //   client(api, on_logout)
    } else {
      console.log("Unknown Role")
    }
  }
  function on_logout() {
    login(boot, on_login)
  }

  //****************************************************************************
  // actions

  var params = new URL(window.location.href).searchParams
  var action = params.get("action")
  if(actions[action]) {
    await actions[action].call(this, boot, params)
  } else {
    on_logout()
  }

})()
.catch(function (err) {
  console.log(err)
})
