var { Frame } = require('./framework.js')

import { fieldErrMsg, thisRemoteError, Modal } from './common.js'

export async function validateEmail(api, params) {

  var token = params.get("token")

  var node = $('body')
  var template = 'validateEmail'
  var frame = new Frame({ node, template })

  frame.$('.save').on('click', async function () {
    var { pass1, pass2 } = await frame.form.pack()
    if(!pass1 || pass1.length < 8) {
      frame.form.error("Le mot de passe doit être d'une longueur minimale de 8 caractères.", "pass1")
      return
    }
    if(pass1 != pass2) {
      frame.form.error("Les mots de passes ne correspondent pas.", "pass2")
      return
    }
    await api.setPassword(token, pass1)
    frame.$('div.newpassword').hide()
    frame.$('div.passwordset').show()
  })

  function changePassword() {
    frame.$('div.newpassword').show()
    //frame.$('input.focus').focus()
  }

  frame.show()

  if(token) {
    try {
      await api.validateEmail(token)
      localStorage.removeItem('credentials')
      changePassword()
    } catch (err) {
      console.log(err)
      frame.$('div.alreadyvalidated').show()
    }
  } else {
    frame.$('div.alreadyvalidated').show()
  }

}

var applyLanguage = function(node, idxLangue) {
  var nodes = node.find(":not(iframe)").addBack().contents().filter(function() {
    return this.nodeType == 3 && this.data.includes('|')
  })
  for(var n of nodes) {
    var text = n.data.split('|')[idxLangue]
    text = text.replace(/\*\*(.+)\*\*/g, '<b>$1</b>')
    //n.data = text
    $(n).parent().html(text)
  }
}

export async function formulaire(api, params) {

  var token = params.get("token")
  var formulaire = await api.getFormulaire(token)
  var entry = await formulaire.load()
  console.log("load", entry)

  $('html').addClass('scroll')

  var node = $('body')
  var template = 'formulaire'
  var frame = new Frame({ node, template })
  var form = frame.form

  frame.$().addClass(`role-client container`)
  frame.$().addClass(`form-${entry.type}`)

  applyLanguage(frame.$(), entry.idxLangue)

  form.$('button.envoyer').on('click', async function () {
    var formdata = await form.pack()
    //console.log(JSON.stringify(formdata, null, 2))
    await formulaire.dataSave(formdata)
    .then(async (entry) => {
      new Modal('sendSuccess').show()
      actions(entry)
    })
    .catch(thisRemoteError('FormError', (err) => {
      console.log(err)
      err.form.forEach((field) => {
        form.error(fieldErrMsg[field.kind] || field.kind, field.path)
      })
      $("body,html").animate({
        scrollTop: frame.$('form p.feedback').first().parents('.field').first().offset().top - 200
      }, 800)
    }))
  })

  function actions(entry) {
    if(entry.type == '') {
      //
    }
    if(entry.statut == 'Complété') {
      form.$('article.complete').show()
      form.$('button.envoyer').hide()
      form.readonly(true)
    }
    if(entry.statut == 'Envoyé') {
      form.$('article.complete').hide()
      form.$('button.envoyer').show()
      form.readonly(false)
    }
  }

  form.load(entry)
  actions(entry)

  frame.show()

}

export async function print(api, params) {

  var token = params.get("token")
  var formulaire = await api.getFormulaire(token)
  var entry = await formulaire.load()
  console.log("load", entry)

  $('html').addClass('scroll')

  var node = $('body')
  var template = 'formulaire'
  var frame = new Frame({ node, template })
  var form = frame.form

  frame.$().addClass(`role-client container`)
  frame.$().addClass(`form-${entry.type}`)

  applyLanguage(frame.$(), entry.idxLangue)

  function prepare(entry) {
    if(entry.type == '') {
      //
    }
    form.$('article.complete').hide()
    form.$('button.envoyer').hide()
    form.readonly(true)
  }

  form.load(entry)
  prepare(entry)
  frame.show()
  window.print()
}