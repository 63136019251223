import { exclusive } from './common.js';

var { Frame } = require('./framework.js')


export function login(boot, on_login) {

  function forgotPassword() {
    $('body').removeClass('has-navbar-fixed-top')

    const node = $('body')
    const template = 'forgotPassword'
    const frame = new Frame({ node, template })

    async function forgotPassword() {
      const { email } = await frame.form.pack()

      await boot.forgotPassword(email)
      frame.$('div.forgotPassword').hide()
      frame.$('div.emailsent').show()

    }  

    frame.$('button.reset').on('click', () => {
      forgotPassword()
      .catch((err) => {
        console.log(err)
      })
    })

    frame.show()
    frame.$('input.focus').focus()

  }

  $('body').removeClass('has-navbar-fixed-top')

  const node = $('body')
  const template = 'login'
  const frame = new Frame({ node, template })

  function login_failed() {
    frame.form.error('Le courriel et/ou le mot de passe est invalide', 'password')
  }

  async function authenticate() {
    frame.$().addClass('wait')
    const { email, password } = await frame.form.pack()

    const api = await boot.login(email, password)
    if(frame.$('.saveCredentials').is(":checked")) {
      localStorage.setItem('credentials', JSON.stringify({ email, password }))
    }
    frame.remove()
    on_login(api)
  }  

  frame.$('button.login').on('click', exclusive(() => {
    authenticate()
    .catch(() => {
      frame.$().removeClass('wait')
      login_failed()
    })
  }))

  frame.$('a.forgotPassword').on('click', () => {
    frame.remove()
    forgotPassword()
    return false
  })

  const credentials = JSON.parse(localStorage.getItem('credentials'))
  if(credentials) {
    frame.$('input[field="email"]').val(credentials.email)
    frame.$('input[field="password"]').val(credentials.password)
    authenticate()
    .catch(() => {
      localStorage.removeItem('credentials')
      login_failed()
      frame.show()
    })
  } else {
    frame.show()
    frame.$('input.focus').focus()
  }

}
